// Generated by Framer (838580a)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
import { Icon as Phosphor1 } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const Phosphor1Controls = getPropertyControls(Phosphor1);

const enabledGestures = {AZv0s377l: {hover: true}};

const cycleOrder = ["AZv0s377l"];

const serializationHash = "framer-aBRpf"

const variantClassNames = {AZv0s377l: "framer-v-lzi0ni"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, id, link, width, ...props}) => { return {...props, r0APp_tOY: link ?? props.r0APp_tOY, RaJOrDQKc: icon ?? props.RaJOrDQKc ?? "LinkedinLogo"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;icon?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, r0APp_tOY, RaJOrDQKc, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "AZv0s377l", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={r0APp_tOY}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-lzi0ni", className, classNames)} framer-jdatcw`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"AZv0s377l"} ref={ref ?? ref1} style={{backdropFilter: "blur(5px)", backgroundColor: "var(--token-c09d492a-e729-4cbb-b155-5952e8c0f0ee, rgb(244, 242, 227))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, WebkitBackdropFilter: "blur(5px)", ...style}} variants={{"AZv0s377l-hover": {backgroundColor: "var(--token-2f560859-5998-4075-847c-9f666c5cfc0b, rgb(10, 10, 10))"}}} {...addPropertyOverrides({"AZv0s377l-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-10edi6p-container"} layoutDependency={layoutDependency} layoutId={"m_Aa_Pbiw-container"}><Phosphor color={"var(--token-6486a9ac-e9e8-4ced-becb-afb4c654ba35, rgb(31, 54, 48))"} height={"100%"} iconSearch={"House"} iconSelection={RaJOrDQKc} id={"m_Aa_Pbiw"} layoutId={"m_Aa_Pbiw"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"} {...addPropertyOverrides({"AZv0s377l-hover": {color: "var(--token-9f0badbf-8d74-41b8-a73a-e1a6bdec487b, rgb(243, 172, 133))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-aBRpf.framer-jdatcw, .framer-aBRpf .framer-jdatcw { display: block; }", ".framer-aBRpf.framer-lzi0ni { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 34px; justify-content: center; overflow: hidden; padding: 6px; position: relative; text-decoration: none; width: 34px; will-change: var(--framer-will-change-override, transform); }", ".framer-aBRpf .framer-10edi6p-container { flex: 1 0 0px; height: 100%; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-aBRpf.framer-lzi0ni { gap: 0px; } .framer-aBRpf.framer-lzi0ni > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-aBRpf.framer-lzi0ni > :first-child { margin-left: 0px; } .framer-aBRpf.framer-lzi0ni > :last-child { margin-right: 0px; } }", ".framer-aBRpf.framer-v-lzi0ni.hover.framer-lzi0ni { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 34px); padding: 4px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 34
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LDhPEF3td":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"r0APp_tOY":"link","RaJOrDQKc":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerOqmWvG9vu: React.ComponentType<Props> = withCSS(Component, css, "framer-aBRpf") as typeof Component;
export default FramerOqmWvG9vu;

FramerOqmWvG9vu.displayName = "Social Icon - Team - Single";

FramerOqmWvG9vu.defaultProps = {height: 34, width: 34};

addPropertyControls(FramerOqmWvG9vu, {r0APp_tOY: {title: "Link", type: ControlType.Link}, RaJOrDQKc: Phosphor1Controls?.["iconSelection"] && {...Phosphor1Controls["iconSelection"], defaultValue: "LinkedinLogo", description: undefined, hidden: undefined, title: "Icon"}} as any)

addFonts(FramerOqmWvG9vu, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})